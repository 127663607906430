import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React from 'react';
import { useForm } from 'react-hook-form';

import { FormDatePickerMemo } from '#shared/components/form/FormDatePicker';
import { FormNumberFieldMemo } from '#shared/components/form/FormNumberField';
import { FormRadioGroupMemo, IRadioOption } from '#shared/components/form/FormRadioGroup';
import { FormTextFieldMemo } from '#shared/components/form/FormTextField';
import { useAuth } from '#shared/hooks/auth';
import { cannotEditDisciplina } from '#shared/utils/getEditPermission';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import {
  IAvaliacaoForm,
  peAvaliacoesFormActions,
} from '#modules/disciplinas/redux/peAvaliacoesFormSlice';
import { avaliacaoSchema, IAvaliacaoSchema } from '#modules/disciplinas/schemas/avaliacaoSchema';
import { modalidadeAvaliacaoValueMap } from '#modules/disciplinas/types/enums/modalidadeAvaliacao';

import { FormCheckbox } from '../FormCheckbox';
import { RecuperacaoInputMemo } from './recuperacao';
import { NaturezasSelectInput } from './selectInput';
import { StyledAvaliacaoForm } from './styles';

interface IAvaliacaoCardForm {
  avaliacao: IAvaliacaoForm;
  numero: number;
  getStatusColor: (form: IAvaliacaoSchema, isValid: boolean) => void;
}

const typeOptions: IRadioOption[] = [
  { label: 'Compõe a média', value: 'media' },
  { label: 'Bônus', value: 'bonus' },
  { label: 'Recuperação', value: 'recuperacao' },
];

const modalidadeOptions = Object.entries(modalidadeAvaliacaoValueMap).map<IRadioOption>(
  ([value, label]) => ({ label, value }),
);

export function AvaliacaoCardForm({ avaliacao, getStatusColor, numero }: IAvaliacaoCardForm) {
  const disciplinaStatus = useAppSelector(disciplinaInfoSelector.status);

  const dispatch = useAppDispatch();

  const { user } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm<IAvaliacaoSchema>({
    resolver: yupResolver(avaliacaoSchema),
    mode: 'onSubmit',
  });

  const tipo = watch('tipo') ?? avaliacao.tipo;

  const handleFormSubmit = (form: IAvaliacaoSchema) => {
    dispatch(
      peAvaliacoesFormActions.updateItem({
        id: avaliacao.id,
        item: {
          ...form,
          idRecuperacao: form.idRecuperacao?.idAvaliacao ?? null,
          data: form.data != null ? form.data.toISOString() : null,
          modalidade: form.modalidade != null ? Number(form.modalidade) : undefined,
        },
      }),
    );
  };

  const handleFormChange = async () => {
    await handleSubmit(handleFormSubmit)();

    const form = getValues();

    let valid = true;

    try {
      avaliacaoSchema.validateSync(form);
    } catch (error) {
      valid = false;
    }

    getStatusColor(form, valid);
  };

  const defaultModalidade = avaliacao.modalidade?.toString() ?? null;

  const cannotEdit = cannotEditDisciplina({ user, disciplinaStatus });

  // console.log(errors);

  return (
    <StyledAvaliacaoForm noValidate>
      <Box className="radio">
        <FormRadioGroupMemo
          row
          control={control}
          name="tipo"
          options={typeOptions}
          defaultValue={avaliacao.tipo}
          customOnChange={handleFormChange}
          disabled={numero <= 2 || avaliacao.idAvaliacao !== 0 || cannotEdit}
        />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} xl={2}>
          <Box className="input">
            <Typography>Data</Typography>

            <FormDatePickerMemo
              control={control}
              name="data"
              errors={errors.data}
              marginType="no-margin"
              customOnChange={handleFormChange}
              defaultValue={avaliacao.data != null ? new Date(avaliacao.data) : null}
              disabled={cannotEdit}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3} xl={4}>
          <Box className="input">
            <label htmlFor={`sigla-${avaliacao.id}`}>Sigla</label>

            <FormTextFieldMemo
              id={`sigla-${avaliacao.id}`}
              control={control}
              name="sigla"
              errors={errors.sigla}
              marginType="no-margin"
              onBlur={handleFormChange}
              defaultValue={avaliacao.sigla}
              disabled={cannotEdit}
            />
          </Box>
        </Grid>

        {tipo === 'recuperacao' ? (
          <Grid item xs={12} sm={9} md={4} xl={4}>
            <Box className="input" sx={{ flex: 1 }}>
              <label htmlFor={`idRecuperacao-${avaliacao.id}`}>Recuperação</label>

              <RecuperacaoInputMemo
                avaliacao={avaliacao}
                error={errors.idRecuperacao}
                control={control}
                handleFormChange={handleFormChange}
              />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} sm={9} md={4} xl={4}>
            <Box className="input" sx={{ flex: 1 }}>
              <label htmlFor={`nome-${avaliacao.id}`}>Instrumento de Avaliação</label>

              <FormTextFieldMemo
                id={`nome-${avaliacao.id}`}
                control={control}
                name="nome"
                errors={errors.nome}
                marginType="no-margin"
                onBlur={handleFormChange}
                defaultValue={avaliacao.nome}
                disabled={cannotEdit}
              />
            </Box>
          </Grid>
        )}

        {tipo === 'media' && (
          <Grid item xs={12} sm={3} md={2} xl={2}>
            <Box className="input">
              <label htmlFor={`peso-${avaliacao.id}`}>Critério/Peso</label>

              <FormNumberFieldMemo
                id={`peso-${avaliacao.id}`}
                control={control}
                name="peso"
                errors={errors.peso}
                marginType="no-margin"
                scale={0}
                min={1}
                max={100}
                onBlur={handleFormChange}
                placeholder="0 a 100%"
                defaultValue={avaliacao.peso}
                disabled={cannotEdit}
              />
            </Box>
          </Grid>
        )}

        {tipo === 'bonus' && (
          <Grid item xs={12} sm={3} md={2} xl={2}>
            <Box className="input">
              <label htmlFor={`bonus-${avaliacao.id}`}>Valor</label>

              <FormNumberFieldMemo
                id={`bonus-${avaliacao.id}`}
                control={control}
                name="bonus"
                errors={errors.bonus}
                marginType="no-margin"
                scale={2}
                min={0}
                max={10}
                onBlur={handleFormChange}
                placeholder="Valor na média"
                defaultValue={avaliacao.bonus?.replace('.', ',')}
                disabled={cannotEdit}
              />
            </Box>
          </Grid>
        )}

        {tipo === 'media' && (
          <>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <FormCheckbox
                  control={control}
                  name="aed"
                  defaultValue={avaliacao.aed ?? false}
                  label="Provêm de atividade em espaço diversificado"
                  onChange={handleFormChange}
                  disabled={cannotEdit}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <FormRadioGroupMemo
                  row
                  control={control}
                  name="modalidade"
                  options={modalidadeOptions}
                  defaultValue={defaultModalidade}
                  customOnChange={handleFormChange}
                  disabled={cannotEdit}
                />

                {errors.modalidade != null && (
                  <Typography color="error" fontSize="0.7rem">
                    {errors.modalidade.message}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <NaturezasSelectInput
                control={control}
                disabled={cannotEdit}
                error={errors.natureza}
                handleFormChange={handleFormChange}
                defaultValue={avaliacao.natureza}
              />
            </Grid>
          </>
        )}
      </Grid>
    </StyledAvaliacaoForm>
  );
}

export const AvaliacaoCardFormMemo = React.memo(AvaliacaoCardForm);
